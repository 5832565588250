/*TODO:
    add datetime attr
*/

import React from "react";
//-----COMPONENTS-----
import Layout from "../components/layout";
import styled from "styled-components";
//-----MATERIAL-----
import Typography from "@material-ui/core/Typography";

import Posts from "../data/posts";

const Article = styled.article`
  margin: 0 0 2em;
  & > p {
    padding: 0 2.5em;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 960px) {
      max-width: 50vw;
    }
  }
`;

const BlogHeader = styled.header`
  position: relative;
  height: 50vh;
  padding: 3em 2.5em 0;
  margin: 0 0 2em;
  background: center / cover no-repeat url(${(props) => props.$bgimg});
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 960px) {
    padding-top: 7%;
    align-items: center;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(#fff 20%, transparent);
    opacity: 90%;
  }

  h1 {
    margin-bottom: 0.25em;
  }
`;

const AuthorNDate = styled.p`
  font-size: 0.75rem;
  font-family: montserrat, sans-serif;
  font-weight: 600;
`;

function Header(props) {
  return (
    <BlogHeader $bgimg={Posts[0].image}>
      <Typography component="h1" variant="h3">
        {Posts[0].title}
      </Typography>
      <AuthorNDate>
        Written by {Posts[0].author}, <time dateTime="">{Posts[0].date}</time>
      </AuthorNDate>
    </BlogHeader>
  );
}

export default () => (
  <Layout>
    <Article>
      <Header />
      {Posts[0].body.map((paragraph) => (
        <Typography variant="body1" paragraph key={paragraph.slice(0, 10)}>
          {paragraph}
        </Typography>
      ))}
    </Article>
  </Layout>
);
